.card {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: fit-content;
    min-height: 250px;
    padding: 50px 0px;
}

.cardInfo {
    flex-grow: 3;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    margin: 0 20px;
}

.cardGutter {
    flex-grow: 2;
    flex-basis: 0;
}

.cardCarouselWrapper {
    flex-grow: 3;
    flex-basis: 0;
    height: 300px;
    border: 1px solid black;
    box-shadow: -5px -5px 3px #ff9061;
    margin: 0 20px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.cardCarouselList {
    height: 100%;
}

.cardImg {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.arrow {
    position: absolute;
    background-color: rgba(0,0,0,0.6);
    color: white;
    padding: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
}

.arrow:hover {
    cursor: pointer;
}

.leftArrow {
    left: 0;
}

.rightArrow {
    right: 0;
}

.cardLinksWrapper {
    margin-top: auto;
    display: flex;
    flex-direction: column;
}

.cardLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    flex-wrap: wrap;
    row-gap: 10px;
}

.cardLinks a {
    text-decoration: none;
    color: black;
}

.cardLink {
    border-radius: 5px;
    box-shadow: -2px -2px 2px black;
    background-color: #ffb08f;
    padding: 7px 12px;
    box-sizing: border-box;
    margin: 0 10px;
}

.cardTextBox {
    border-radius: 5px;
    box-shadow: -2px -2px 2px black;
    background-color: #ff9061;
    padding: 7px 12px;
    box-sizing: border-box;
    margin: 0 10px;
}

.m-0 {
    margin: 0;
}

.cardInfo h1 {
    text-align: center;
}





@media(max-width: 1250px) {
    .cardGutter {
        flex-grow: 1;
    }
}

@media(max-width: 1000px) {
    .cardGutter {
        flex-grow: 0;
        margin: 30px;
    }
}

@media(max-width: 850px) {
    .cardGutter {
        margin: 0;
    }

    .card {
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
    }

    .cardInfo {
        margin-top: 20px;
    }

    .cardCarouselWrapper {
        min-height: 300px;
    }
}

@media(max-width: 500px) {
    .card {
        padding-left: 0px;
        padding-right: 0px;
    }
}