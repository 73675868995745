#projType {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    font-size: 1.2em;
    align-items: center;
    justify-content: center;
}

.projTypeItem {
    flex-basis: 0;
    border: 1px solid #333333;
    background-color: #ff9061;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
}

    .projTypeItem:hover {
        cursor: pointer;
        border: 2px solid #333333;
        background-color: #ffb08f;
    }

    .projTypeItem.selected {
        cursor: pointer;
        border: 2px solid #333333;
        background-color: #ffb08f;
    }

#showProjects > :nth-child(2n+1) > .card {
    flex-direction: row;
}

@media(max-width: 850px) {
    #showProjects > :nth-child(2n+1) > .card {
        flex-direction: column;
    }
}

hr {
    width: 75%;
}