.homeImage{
    flex-basis: 0;
    flex-grow: 2;
    text-align: center;
}

    .homeImage img {
        width: 100%;
        max-width: 250px;
        border-radius: 100%;
    }

.homeWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.homeGutter {
    flex-basis: 0;
    flex-grow: 1;
}

.homeInfo {
    flex-basis: 0;
    flex-grow: 4;
    padding: 0 20px;
}

.homeWrapper {
    height: 100vh;
}

.homeName {
    font-size: 3em;
}

.arrow-bounce {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    margin-top: 80vh;
    z-index: 3;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-image: url("../cssAssets/downArrow.svg");
    background-size: contain;
}

.bounce {
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.homeContainer {
    position: relative;
}

@media(max-width: 850px) {
    .homeGutter {
        flex-grow: 0.5;
    }
}

@media(max-width: 700px) {
    .homeGutter {
        flex-grow: 1;
    }

    .homeWrapper {
        flex-direction: column;
    }

    .arrow-bounce {
        margin-top: 92vh;
    }
}

@media(max-width: 500px) {
    .homeImage img {
        max-width: 200px;
    }

    .homeName {
        font-size: 2em;
    }

    .homeInfo {
        font-size: .8em;
    }
}