/* YourComponent.css */

.side-navigation {
    position: fixed;
    top: 0;
    left: 0px;
    bottom: 0;
    margin: auto;
    width: 100px; /* Adjust the width as needed */
    height: 100%; /* Take full height of the viewport */
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white; /* Adjust the text color as needed */
    z-index: 10;
    transition: top 0.5s ease;
    font-size: 13px;
    font-weight: 700;
}

.side-nav-bg {
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 100%;
    width: 200px;
    height: 300px;
    left: -100px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 5;
}

.nav-item {
    cursor: pointer;
    margin: 10px;
    transition: transform 0.3s, opacity 0.3s;
    height: 30px;
    z-index: 1;
}

.transparent0 {
    opacity: 1;
    transform: scale(1.25) !important;
}

.transparent1 {
    opacity: 0.5;
}

.transparent2 {
    opacity: 0.4;
}

.transparent3 {
    opacity: 0.3;
}

.transparent4 {
    opacity: 0.2;
}

@media(max-width: 1100px) {
    .side-navigation div {
        transform: none !important;
        padding-top: 10px;
    }

    .side-navigation {
        width: 100%;
        height: 50px;
        flex-direction: row;
        margin: 0;
        top: 0 !important;
        background: rgba(0, 0, 0, 0.6);
    }

    .side-nav-bg {
        display: none;
    }
}

@media(max-width: 400px) {
    .side-navigation div {
        font-size: 10px;
        padding-top: 15px;
    }
}